// Entry point for the build script in your package.json

import jquery from 'jquery'
window.jQuery = jquery
window.$ = jquery

import "@fortawesome/fontawesome-free/js/brands.min.js"
import "@fortawesome/fontawesome-free/js/fontawesome.min.js"

import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
