import { Controller } from "@hotwired/stimulus"

import 'slick-carousel'

// Connects to data-controller="talenten"
export default class extends Controller {
  initialize() {
    $(".slider").not('.slick-initialized').slick({

        arrows: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '100px',

        responsive: [{

          breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            }

          }, {

          breakpoint: 1024,
            settings: {
              slidesToShow: 1,
            }

          }, {

          breakpoint: 600,
            settings: {
              slidesToShow: 1,
              centerPadding: '30px',
              // centerMode: false,
            }

          }, {

             breakpoint: 300,
            settings: "unslick" // destroys slick

          }]


      });
  }
}
