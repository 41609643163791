import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="vacancies"
export default class extends Controller {
  static targets = [ "count" ]

  connect() {
    console.log("connected");
    this.updatecount(document.getElementsByClassName('vacancybox').length);
    this.updateresults();
  }

  filter_field(event) {
    this.filter('f', event.params.fid);
  }

  filter_elevel(event) {
    this.filter('l', event.params.fid);
  }

  filter(tag, id) {
    const filter = tag + id;
    const option = document.getElementById("t" + filter);
    option.classList.toggle("selected");

    const list = document.getElementsByClassName(filter);
    for (var i = 0; i < list.length; i++) {
      list[i].classList.toggle("found");
    }

    this.updateresults();
    event.preventDefault();
  }

  updateresults(){
    const list = document.getElementsByClassName('selected');
    const results = document.getElementById('results');

    document.getElementById("noresults").classList.add("d-none");

    if ( list.length > 0 ) {
      results.classList.add('filtering');

      const found = document.getElementsByClassName('found');
      this.updatecount(found.length);
      if ( found.length == 0 ) {
        document.getElementById("noresults").classList.remove("d-none");
      }
    }
    else {
      this.updatecount(document.getElementsByClassName('vacancybox').length);
      results.classList.remove('filtering');
    }
  }

  updatecount(count) {
    this.countTarget.textContent = count;
  }


}
