import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="topmenu"
export default class extends Controller {

  initialize()  {
    this.found = false;
  }

  connect() {
    var _self = this;

    document.addEventListener('scroll', function(e) {
      let lastKnownScrollPosition = window.scrollY;
      if (lastKnownScrollPosition > 100 && !_self.found) {
        console.log(lastKnownScrollPosition)
        document.getElementById('topmenu').classList.add("scrolled");
        document.getElementById('navbar').classList.add("scrolled");
        _self.found = true
      }

      if (lastKnownScrollPosition < 100 && _self.found) {
        document.getElementById('topmenu').classList.remove("scrolled");
        document.getElementById('navbar').classList.remove("scrolled");
        _self.found = false
      }
    }, { passive: true });
  }

  disconnect() {
    document.removeEventListener('scroll', null, { passive: true });
  }
}
